import tpl from './content.tpl.html';

class PrivacyPolicyAlert {
	/**
	 * @typedef {Object} PrivacyPolicyAlertOptions
	 * @property {string} heading
	 * @property {string} text
	 * @property {string} acceptText
	 * @property {string} declineText
	 * 
	 * @typedef {Object} PrivacyPolicyAlertParams
	 * @property {HTMLElement} container
	 * @property {Function} onAccept
	 * @property {Function} onClose
	 * @property {Function} onDecline
	 * @property {PrivacyPolicyAlertOptions} options
	 */

	/**
	 * @param {PrivacyPolicyAlertParams} params
	 */
	constructor(params = {}) {
		const options = Object.assign({
			heading: "This website uses cookies",
			text: "We use cookies to track you thought the whole internet and detect wich sites you visited before. Please accept the following cookies to get the most from curator.pro. See our <a href='https://curator.pro'>cookie policy</a> for more information.",
			acceptText: "Accept",
			declineText: "Decline",
		}, params.options || {});
		/**
		 * @member {PrivacyPolicyAlertParams}
		 */
		this.params = Object.assign(
			{
				onAccept: () => null,
				onClose: () => null,
				onDecline: () => null,
				container: document.body,
			},
			params,
			{ options: options }
		);
		this._isVisible = false;

		/**
		 * @member {HTMLElement}
		 */
		this.content = document.createElement("div");
		this.content.innerHTML = this.render();
		this.params.container.appendChild(this.content);

		/**
		 * @member {HTMLElement}
		 */
		this.root = this.content.querySelector(".privacyPolicyAlert-root");

		/**
		 * @member {HTMLElement}
		 */
		this.closeBtn = this.root.querySelector(".privacyPolicyAlert-closeBtn");

		/**
		 * @member {HTMLElement}
		 */
		this.acceptBtn = this.root.querySelector(".privacyPolicyAlert-acceptButton");

		/**
		 * @member {HTMLElement}
		 */
		this.declineBtn = this.root.querySelector(".privacyPolicyAlert-declineButton");

		this.closeBtn.addEventListener("click", this.params.onClose.bind(this));
		this.acceptBtn.addEventListener("click", this.params.onAccept.bind(this));
		this.declineBtn.addEventListener("click", this.params.onDecline.bind(this));
	}

	get isVisible() {
		return this._isVisible;
	}

	show() {
		if (!this.isVisible) {
			this._isVisible = true;
			this.root.classList.add("privacyPolicyAlert-root--visible");
		}
	}

	hide() {
		if (this.isVisible) {
			this._isVisible = false;
			this.root.classList.remove("privacyPolicyAlert-root--visible");
		}
	}

	/**
	 * @param {Object} styles 
	 */
	stylesToString(styles) {
		return Object.entries(styles).map(kv => kv.join(": ")).join("; ");
	}

	render() {
		/** @type {string} */
		const template = tpl;
		const tplData = this.params.options;
		const res = template.replace(/\{\{(.+?)\}\}/g, substring => {
			const key = substring.match(/\{\{(.+)\}\}/)[1].trim();
			return tplData[key] || "";
		});
		return res;
	}
}

(function() {
	window.PrivacyPolicyAlert = PrivacyPolicyAlert;
})()
